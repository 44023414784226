import React, { useState } from "react";
import styled from "styled-components";
import mileageIcon from "./assets/mileage.svg";
import CalendarImage from "./assets/white-calendar.png";
import vehiclePlaceholder from "./assets/vehiclePlaceholder.png";
import { strings } from "../../../utils/localization";

const CardContainer = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e9f2;
  width: 250px;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 43px;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
`;

const CarImage = styled.img`
  width: 66px;
  height: 66px;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 5px;
  background: #f6f7fb;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #0e0e0e;
  margin: 0;
  width: 100%;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-transform: capitalize;
`;

const Price = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b48e6;
  margin: 4px 0;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #77808d;
  line-height: 18px;
  margin-bottom: 8px;
  margin-top: 2px;
`;

const MileageIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const InterestedButton = styled.button`
  width: 100%;
  padding: 7px;
  background-color: #3b48e6;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #2e3ab7;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const CalendarIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const Tag = styled.div`
  color: #77808d;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 6px;
  background: #f6f7fb;
  padding: 2px 4px;
  margin-right: 4px;
  margin-bottom: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallCarTile = ({ item, scheduleTestDrive, readOnly }) => {
  const [testDriveClicked, setTestDriveClicked] = useState(false);

  const handleTestDriveClick = () => {
    if (!testDriveClicked) {
      scheduleTestDrive(item);
    }
    setTestDriveClicked(true);
  };

  const formatPrice = () => {
    const price = item?.data?.price;
    if (!price) {
      return strings.PRICE_PENDING;
    }
    const priceFloat = parseFloat(price);
    if (priceFloat % 1 === 0) {
      return (
        "$" +
        Math.round(priceFloat)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    }
    return "$" + priceFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderSubmit = () => {
    if (readOnly) return null;
    return (
      <InterestedButton
        onClick={handleTestDriveClick}
        disabled={testDriveClicked}
      >
        <CalendarIcon src={CalendarImage} alt="calendar" />

        {strings.SCHEDULE_TEST_DRIVE_LABEL}
      </InterestedButton>
    );
  };

  return (
    <CardContainer key={item?.id}>
      <Content>
        <CarImage
          src={item?.data?.imageUrl}
          alt={item?.data?.title}
          onError={(e) => {
            e.target.src = vehiclePlaceholder;
          }}
        />
        <DetailsContainer>
          <Title>{item?.data?.title}</Title>
          <Price>{formatPrice()}</Price>
          <InfoRow>
            <Tag style={{ textTransform: "uppercase" }}>
              #{item?.data?.stockNumber}
            </Tag>
            <Tag>
              <MileageIcon src={mileageIcon} alt="mileage" />
              {item?.data?.mileage} mi
            </Tag>
          </InfoRow>
        </DetailsContainer>
      </Content>
      <BottomRow>{renderSubmit()}</BottomRow>
    </CardContainer>
  );
};

export default SmallCarTile;
