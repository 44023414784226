import React, { useState } from "react";
import styled from "styled-components";
import AcceptTermsFooter from "../../Footer/AcceptTermsFooter";
import { strings } from "../../../../utils/localization";
import "../../../../styles/LiveChatStyle.css";
import ModernPhoneNumberField from "../../../../components/Fields/ModernPhoneNumberField";
import ModernInput from "../../Input/ModernInput";
import ModerSubmitButton from "../../../../components/Buttons/ModerSubmitButton";
import { useChatContext } from "../../../contexts/ChatContext";
import { useSimpleLeadForm } from "../../../../utils/useSimpleLeadForm";
import { useBooking } from "../../../..//utils/useBooking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.form`
  max-width: 400px;
  margin: 0 auto;

  .matadot-live-chat-123789-main-input {
    width: 100%;
    height: 46px;
    border-radius: 5px !important;
  }

  .flag-dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const Note = styled.p`
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #c43423;
  margin-bottom: 2px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Form = styled.div`
  border-radius: 14px 14px 0px 14px;
  margin-bottom: 10px;

  .matadot-live-chat-123789-main-input {
    background-color: #f9f9f9;
    border-bottom-width: 1.3px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #17181b;
  }

  .matadot-live-chat-123789-main-input:focus {
    border-bottom-color: ${({ primaryColor }) => primaryColor};
  }
`;

const RightContent = styled.div`
  background-color: #fafbfd;
  padding: 12px;
  border-radius: 16px;
  max-width: 90%;
`;

const BookingContainer = styled.div`
  padding: 12px;
  border-radius: 16px;
  width: 100%;
  margin-top: 10px;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  display: block;
  color: #77808d;
  margin-bottom: 2px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  border-radius: 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 15px;
  border-radius: 8px;
`;

const LeadAppointmentForm = ({
  chatData,
  submit,
  firstAndLastNameSeparately,
  currentUrl,
  message,
}) => {
  const { chatCustomer, chatAppointment, readOnly } = useChatContext();
  let backgroundColor = chatData && chatData.color ? chatData.color : "red";
  const [formSubmitted, setFormSubmitted] = useState(false);

  const scrollToBottom = () => {
    const el = document.getElementById("matador-connect-main-content");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  const {
    name,
    firstName,
    lastName,
    phoneNumber,
    formSubmitted: userFormSubmitted,
    mobileNumberError,
    isFormValid,
    handleNameChange,
    handleMobileNumberChange,
    setFirstName: setUserFirstName,
    setLastName: setUserLastName,
    setName: setUserName,
    getPhoneNumberFromClipboard,
  } = useSimpleLeadForm({
    chatData,
    chatCustomer,
    scrollToBottom,
  });

  const areBothFormsValid = () => {
    return isFormValid && selectedDate && selectedTime;
  };

  const {
    selectedDate,
    selectedTime,
    availableTimes,
    currentDay,
    isDateOpen,
    handleDateChange,
    handleTimeChange,
  } = useBooking({
    organizationTimeZone: chatData?.organizationTimeZone,
    organizationWorkingHours: chatData?.organizationWorkingHours,
    organizationCustomWorkingHours:
      chatData?.organizationCustomWorkingHours || [],
    chatAppointment,
  });

  const handleCombinedSubmit = async (e) => {
    e.preventDefault();
    if (areBothFormsValid()) {
      setFormSubmitted(true);
      submit({
        firstName,
        lastName,
        name,
        phoneNumber,
        date: selectedDate,
        time: selectedTime,
        messageId: message?._id,
      });
    }
  };

  const renderNameFields = () => {
    if (firstAndLastNameSeparately) {
      return (
        <>
          <ModernInput
            placeholder={strings.FIRST_NAME}
            color={backgroundColor}
            value={firstName}
            backgroundColor="#fff"
            onChange={(value) => setUserFirstName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCombinedSubmit(e);
              }
            }}
            onFocus={scrollToBottom}
            required={chatData?.isCustomerNameRequired}
            enableValidation={chatData?.isCustomerNameRequired}
            formSubmitted={userFormSubmitted}
            borderDisabled
            marginBottom={12}
          />
          <ModernInput
            placeholder={strings.LAST_NAME}
            color={backgroundColor}
            value={lastName}
            backgroundColor="#fff"
            onChange={(value) => setUserLastName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCombinedSubmit(e);
              }
            }}
            onFocus={scrollToBottom}
            required={chatData?.isCustomerNameRequired}
            enableValidation={chatData?.isCustomerNameRequired}
            formSubmitted={userFormSubmitted}
            borderDisabled
          />
          <div style={{ height: 8 }}></div>
        </>
      );
    }

    return (
      <ModernInput
        placeholder={strings.FULL_NAME}
        color={backgroundColor}
        value={name}
        backgroundColor="#fff"
        onChange={handleNameChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleCombinedSubmit(e);
          }
        }}
        onFocus={scrollToBottom}
        required={chatData?.isCustomerNameRequired}
        enableValidation={chatData?.isCustomerNameRequired}
        formSubmitted={userFormSubmitted}
        borderDisabled
        marginBottom={12}
      />
    );
  };

  const renderSubmit = () => {
    if (readOnly) return null;
    return (
      <>
        <AcceptTermsFooter
          color={chatData?.termsLink}
          termsLink={chatData?.termsLink}
          margin={0}
          locationId={chatData?._location_id}
          currentUrl={currentUrl}
        />

        <ModerSubmitButton
          disabled={!areBothFormsValid() || message?.isSubmitted}
          marginTop={18}
          color={chatData?.color}
          secondaryColor={chatData?.secondaryColor}
          type="submit"
        />
      </>
    );
  };

  return (
    <Container onSubmit={handleCombinedSubmit}>
      <FormContainer>
        <RightContent>
          <Form primaryColor={chatData?.color}>
            {renderNameFields()}

            <ModernPhoneNumberField
              value={phoneNumber}
              onChange={(value) => handleMobileNumberChange(value)}
              defaultCountryFlag={
                chatData?.organization_country_code?.toLowerCase() || "us"
              }
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
              token={chatData?._token}
            />

            {userFormSubmitted && mobileNumberError && (
              <Note style={{ marginTop: 3, marginBottom: 5 }}>
                {strings.REQUIRED_FIELD}
              </Note>
            )}
          </Form>

          <BookingContainer>
            <FormField>
              <Label htmlFor="date-picker">{strings.DATE}</Label>
              <StyledDatePicker
                id="date-picker"
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMMM d, yyyy"
                placeholderText={strings.CHOOSE_DATE}
                filterDate={isDateOpen}
                minDate={currentDay}
                required
              />
              {userFormSubmitted && !selectedDate && (
                <Note style={{ marginTop: 3, marginBottom: 5 }}>
                  {strings.REQUIRED_FIELD}
                </Note>
              )}
            </FormField>

            <FormField>
              <Label htmlFor="time-select">{strings.TIME}</Label>
              <Select
                id="time-select"
                value={selectedTime}
                onChange={handleTimeChange}
                disabled={!selectedDate}
                aria-label={strings.TIME}
              >
                <option value="">{strings.CHOOSE_TIME}</option>
                {availableTimes.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </Select>
              {userFormSubmitted && !selectedTime && (
                <Note style={{ marginTop: 3, marginBottom: 5 }}>
                  {strings.REQUIRED_FIELD}
                </Note>
              )}
            </FormField>
          </BookingContainer>

          {renderSubmit()}
        </RightContent>
      </FormContainer>
    </Container>
  );
};

export default LeadAppointmentForm;
