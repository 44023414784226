import React, { useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styled from "styled-components";
import CarTile from "./CarTile";
import { ReactComponent as ArrowLeftIcon } from "./assets/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "./assets/arrowRight.svg";
import AwardTile from "./AwardTile";
import { useChatContext } from "../../contexts/ChatContext";

const Embla = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  margin-top: 10px;
`;

const EmblaViewport = styled.div`
  overflow: hidden;
  width: 100%;
  padding-left: 35px;
  position: relative;
`;

const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
  padding-right: 50px;
`;

const EmblaSlide = styled.div`
  position: relative;
  padding-left: 15px;
  //height: 100%; temp removed to make cards dynamic height
`;

const NavigationButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 25px;
  height: 25px;
`;

const LeftButton = styled(NavigationButton)`
  left: -15px;
`;

const RightButton = styled(NavigationButton)`
  right: 15px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
`;

const RightOverlay = styled(Overlay)`
  right: 0;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 68px;
`;

const LeftOverlay = styled(Overlay)`
  left: 0;
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 50px;
`;

const ChatWidgetSlider = ({
  data,
  imInterested,
  scheduleTestDrive,
  color,
  secondaryColor,
  redirectToVdp,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
  });

  const [items, setItems] = useState(data);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [canScrollPrev, setCanScrollPrev] = useState(false);

  const { readOnly } = useChatContext();

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCanScrollNext(emblaApi.canScrollNext());
      setCanScrollPrev(emblaApi.canScrollPrev());
    };

    emblaApi.on("select", onSelect).on("reInit", onSelect);
    onSelect();

    return () => emblaApi.off("select", onSelect);
  }, [emblaApi, data]);

  const scrollNext = () => {
    const hasAward = items.some((item) => item.type === "AWARD");
    if (emblaApi) {
      if (hasAward) {
        removeAwardItems();
        setTimeout(() => {
          emblaApi.scrollNext();
        }, 50);
      } else {
        emblaApi.scrollNext();
      }
    }
  };

  const scrollPrev = () => {
    const hasAward = items.some((item) => item.type === "AWARD");
    if (emblaApi) {
      if (hasAward) {
        removeAwardItems();
        setTimeout(() => {
          emblaApi.scrollPrev();
        }, 50);
      } else {
        emblaApi.scrollPrev();
      }
    }
  };

  const removeAwardItems = () => {
    const array = items.filter((item) => item.type !== "AWARD");
    setItems(array);
  };

  const setActiveItem = (item) => {
    const currentActiveIndex = emblaApi.selectedScrollSnap();
    const newItem = {
      ...item,
      id: `${item.id}-AWARD`,
      type: "AWARD",
    };
    const array = [...items];
    array.splice(currentActiveIndex, 0, newItem);
    setItems(array);
  };

  return (
    <Embla>
      {canScrollPrev && <LeftOverlay />}
      {canScrollNext && <RightOverlay />}
      {canScrollPrev && (
        <LeftButton onClick={scrollPrev}>
          <ArrowLeftIcon />
        </LeftButton>
      )}
      <EmblaViewport ref={emblaRef}>
        <EmblaContainer>
          {items?.map((item) => (
            <div key={item.id}>
              {item.type === "AWARD" ? (
                <EmblaSlide className="tiles_slide">
                  <AwardTile item={item} />
                </EmblaSlide>
              ) : (
                <EmblaSlide className="tiles_slide">
                  <CarTile
                    item={item}
                    setActiveItem={setActiveItem}
                    imInterested={imInterested}
                    scheduleTestDrive={scheduleTestDrive}
                    color={color}
                    secondaryColor={secondaryColor}
                    redirectToVdp={redirectToVdp}
                    readOnly={readOnly}
                  />
                </EmblaSlide>
              )}
            </div>
          ))}
        </EmblaContainer>
      </EmblaViewport>
      {canScrollNext && (
        <RightButton onClick={scrollNext}>
          <ArrowRightIcon />
        </RightButton>
      )}
    </Embla>
  );
};

export default ChatWidgetSlider;
