import React from "react";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "./assets/back-icon.svg";
import { ReactComponent as CloseIcon } from "./assets/close-icon.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 1000;
`;

const Back = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  backdrop-filter: blur(2px);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const CloseContainer = styled.div`
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  background-color: rgba(0, 0, 0, 0.05);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.2));
`;

const ChatHeader = ({ goBack }) => {
  const closeMatdorConnect = () => {
    window.top.postMessage("close_matador_connect", "*");
  };

  const closeIcon = () => {
    return (
      <CloseContainer onClick={closeMatdorConnect}>
        <IconWrapper>
          <CloseIcon height={15} width={15} stroke={"#888888"} />
        </IconWrapper>
      </CloseContainer>
    );
  };

  return (
    <Container>
      <Back onClick={goBack}>
        <BackIcon />
      </Back>
      <span></span>
      {closeIcon()}
    </Container>
  );
};

export default ChatHeader;
