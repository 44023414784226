import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Message from "./Message";
import ChatWidgetSlider from "../Tiles/ChatWidgetSlider";
import SmallCarTile from "../Tiles/SmallCarTile";
import typingLottie from "./assets/animations/typing.json";
import Lottie from "react-lottie";
import { useChatContext } from "../../contexts/ChatContext";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: typingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  padding-top: ${(props) => (props.readOnly ? "0px" : "50px")};

  .loader {
    height: 60px;

    circle {
      fill: ${(props) => `${props.color} !important`};
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  margin-top: -20px;
  margin-left: -5px;
`;

const MessagesList = ({
  messages,
  token,
  isLoading,
  avatarImg,
  SubmitForm,
  secondaryColor,
  color,
  imInterested,
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  hideAppointmentBooking,
  scheduleTestDrive,
  UserLeadForm,
  onRetry,
  redirectToVdp,
}) => {
  const messagesEndRef = useRef(null);
  const [delayedLoading, setDelayedLoading] = useState(false);
  const { readOnly } = useChatContext();

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
        setDelayedLoading(true);
      }, 3000);
    } else {
      setDelayedLoading(false);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (readOnly) return;
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderMessage = ({ message, index }) => {
    return (
      <Message
        message={message}
        secondaryColor={secondaryColor}
        avatarImg={avatarImg}
        SubmitForm={SubmitForm}
        //nextIsSent={messages[index + 1]?.role !== "user"}
        color={color}
        organizationTimeZone={organizationTimeZone}
        organizationWorkingHours={organizationWorkingHours}
        organizationCustomWorkingHours={organizationCustomWorkingHours}
        hideAppointmentBooking={hideAppointmentBooking}
        timestamp={message.timestamp}
        scrollToBottom={scrollToBottom}
        UserLeadForm={UserLeadForm}
        isLastMessage={index === messages?.length - 1}
        onRetry={onRetry}
        readOnly={readOnly}
      />
    );
  };

  const renderContent = (message, index) => {
    const { type, hidden } = message;

    if (hidden) return null;

    if (type === "car_details") {
      return (
        <div>
          {message?.text &&
            renderMessage({
              message,
              index,
            })}
          <SmallCarTile
            item={message.inventory}
            scheduleTestDrive={scheduleTestDrive}
            readOnly={readOnly}
          />
        </div>
      );
    }

    if (type === "slider") {
      return (
        <div>
          {message?.text &&
            renderMessage({
              message,
              index,
            })}

          <ChatWidgetSlider
            data={message.inventory}
            imInterested={imInterested}
            scheduleTestDrive={scheduleTestDrive}
            color={color}
            secondaryColor={secondaryColor}
            redirectToVdp={redirectToVdp}
          />
        </div>
      );
    }

    return renderMessage({
      message,
      index,
    });
  };

  return (
    <Content color={color} readOnly={readOnly} id="messages-container">
      {messages.map((message, index) => {
        return <div key={index}>{renderContent(message, index)}</div>;
      })}
      {delayedLoading && isLoading && (
        <LoaderContainer>
          <Lottie
            options={defaultOptions}
            height={100}
            width={60}
            style={{
              margin: 0,
            }}
          />
        </LoaderContainer>
      )}
      <div ref={messagesEndRef} />
    </Content>
  );
};

export default MessagesList;
