import React, { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import notificationSound from "./assets/notification.wav";
import outboundSound from "./assets/outbound.wav";
import AiChatApi from "../components/Messaging/ai-chat-api";

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

const formatTimestamp = (date) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Intl.DateTimeFormat("default", options)
    .format(date)
    .replace(",", "");
};

export const ChatProvider = ({ children }) => {
  const [chatId, setChatId] = useState(() => {
    const savedChatId = localStorage.getItem("-CHAT-ID-");
    return savedChatId || null;
  });

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const [isSending, setIsSending] = useState(false);

  const [logs, setLogs] = useState([]);
  const [chatCustomer, setChatCustomer] = useState(null);
  const [chatAppointment, setChatAppointment] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState("");

  const getUserId = () => {
    let storedUserId = localStorage.getItem("-USER-ID-") || "";
    return storedUserId;
  };

  const setUserId = (id) => {
    localStorage.setItem("-USER-ID-", id);
  };

  useEffect(() => {
    if (chatId) {
      localStorage.setItem("-CHAT-ID-", chatId);
    }
  }, [chatId]);

  useEffect(() => {
    if (!readOnly) {
      checkInactiveConversation();
    }
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const handleStorage = (event) => {
      if (event.key === "messages" && event.newValue) {
        const updatedMessages = JSON.parse(event.newValue);
        if (Array.isArray(updatedMessages)) {
          setMessages(updatedMessages);
        }
      }
      if (event.key === "chatId" && event.newValue) {
        setChatId(event.newValue);
      }
      if (event.key === "isSending") {
        setIsSending(event.newValue === "true");
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const playOutboundSound = () => {
    const audio = new Audio(outboundSound);
    audio.play();
  };

  const setLoading = (value) => {
    setIsSending(value);
    localStorage.setItem("isSending", value.toString());
  };

  const triggerUrlRedirection = ({ url }) => {
    window.top.postMessage(
      {
        type: "MATADOR_REDIRECT_TO_URL",
        url,
      },
      "*"
    );
  };

  const updateContact = async ({
    firstName,
    lastName,
    phoneNumber,
    email,
    appointmentDate,
    appointmentTime,
    locationId,
    language,
    messageId,
  }) => {
    if (readOnly) return;
    const userId = getUserId();
    let date = "";
    let time = "";

    if (appointmentDate) {
      date = moment(appointmentDate).format("MMMM DD, YYYY");
    }
    if (appointmentTime) {
      time = moment(appointmentTime, "h:mm A").format("HH:mm");
    }

    const appointmentData = {
      language,
      turnstileToken,
      ...(chatId && { chatId }),
      ...(userId && { userId }),
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(phoneNumber && { phoneNumber }),
      ...(email && { email }),
      ...(date && { appointmentDate: date }),
      ...(time && { appointmentTime: time }),
    };

    setLoading(true);

    try {
      playOutboundSound();

      const response = await AiChatApi.updateContact({
        locationId,
        param: appointmentData,
      });

      if (response.data.success) {
        const { message, type, inventory, _id, customer, appointment } =
          response.data.response;

        playNotificationSound();

        setChatCustomer(customer);
        setChatAppointment(appointment);

        const botNow = new Date();
        const botMessages = [
          {
            _id,
            text: message,
            type,
            inventory,
            role: "assistant",
            createdAt: botNow.toISOString(),
            timestamp: formatTimestamp(botNow),
          },
        ];

        if (messageId) {
          updateIsSubmitted({ messageId });
        }
        setMessages((prev) => [...prev, ...botMessages]);
        setLogs((prevLogs) => [...prevLogs, { ...response.data }]);
      } else {
        throw new Error("Error in response");
      }
    } catch (error) {
      throw error?.response?.data?.message || "Something went wrong";
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async ({
    text,
    url,
    locationId,
    messageId,
    playSound = true,
    isRetry = false,
    isInChatView,
    language,
  }) => {
    if (readOnly) return;
    const userId = getUserId();

    if (!text.trim()) return;

    let randomId = uuidv4();
    const now = new Date();
    const userMessage = messageId
      ? messages.find((msg) => msg._id === messageId)
      : {
          text,
          role: "user",
          timestamp: formatTimestamp(now),
          createdAt: now.toISOString(),
          _id: randomId,
          status: "sending",
        };

    if (!messageId) {
      setMessages((prev) => [...prev, userMessage]);
    } else if (isRetry) {
      setMessages((prev) =>
        prev.map((msg) =>
          msg._id === messageId ? { ...msg, status: "sending" } : msg
        )
      );
    }

    setLoading(true);

    try {
      playOutboundSound();
      const data = {
        message: text,
        language,
        turnstileToken,
        //url,
      };

      if (chatId) {
        data.chatId = chatId;
      }
      if (userId) {
        data.userId = userId;
      }

      const response = await AiChatApi.sendMessage({ locationId, param: data });

      if (response.data.success && isInChatView) {
        const { message, type, inventory, customer, appointment, chatSession } =
          response.data.response;
        if (playSound) {
          playNotificationSound();
        }

        if (customer?._id) {
          setUserId(customer._id);
        }

        if (chatSession?._id) {
          setChatId(chatSession._id);
        }

        setChatCustomer(customer);
        setChatAppointment(appointment);

        const randomId = uuidv4();

        const botNow = new Date();
        const botMessages = [
          {
            _id: randomId,
            text: message,
            type,
            inventory,
            role: "assistant",
            createdAt: botNow.toISOString(),
            timestamp: formatTimestamp(botNow),
          },
        ];
        setMessages((prev) => [...prev, ...botMessages]);
        setLogs((prevLogs) => [...prevLogs, { ...response.data }]);
      } else {
        throw new Error("Error in response");
      }
    } catch (error) {
      handleError({ error, newMessageId: userMessage._id });
    } finally {
      setLoading(false);
    }
  };

  const updateIsSubmitted = ({ messageId }) => {
    const updatedMessages = messages.map((message) => {
      if (message._id === messageId) {
        return { ...message, isSubmitted: true };
      }
      return message;
    });
    setMessages(updatedMessages);
  };

  const handleError = ({ error, newMessageId }) => {
    setMessages((prev) =>
      prev.map((msg) =>
        msg._id === newMessageId
          ? {
              ...msg,
              status: "failed",
              error: error?.response?.data?.error || "Failed to send message",
            }
          : msg
      )
    );
    throw error?.response?.data?.message || "Something went wrong";
  };

  const clearChat = (removeUser = false) => {
    setMessages([]);
    setLogs([]);
    localStorage.removeItem("-CHAT-ID-");
    localStorage.removeItem("messages");
    localStorage.removeItem("isSending");
    setChatId("");
    setLoading(false);
    if (removeUser) {
      localStorage.removeItem("-USER-ID-");
    }
  };

  const checkInactiveConversation = () => {
    if (messages?.length === 0 || !messages) {
      return;
    }

    const lastMessage = messages[messages.length - 1];
    if (!lastMessage || !lastMessage.createdAt) return;

    const lastMessageTime = new Date(lastMessage.createdAt).getTime();
    const now = Date.now();
    const minutes10 = 10 * 60 * 1000;

    if (now - lastMessageTime > minutes10) {
      clearChat(true);
    }
  };

  const setPreloadedMessages = (prefillData) => {
    if (!prefillData?.messages) return;
    setReadOnly(true);
    setChatId(prefillData?._id || "");

    const formattedMessages = prefillData.messages.map((msg) => ({
      ...msg,
      _id: msg._id,
      text: msg.message,
      role: msg.role,
      type: msg.type,
      createdAt: msg.createdAt,
      timestamp: formatTimestamp(new Date(msg.createdAt)),
    }));

    setMessages(formattedMessages);

    if (prefillData.customer) {
      const randomId = uuidv4();
      setUserId(randomId);

      setChatCustomer({
        ...chatCustomer,
        fullName: prefillData.customer.fullName,
        phone: prefillData.customer.phone,
      });
    }

    if (prefillData.appointment) {
      setChatAppointment({
        ...chatAppointment,
        date: prefillData.appointment.date,
        time: prefillData.appointment.time,
      });
    }
  };

  const value = {
    messages,
    sendMessage,
    isSending,
    chatId,
    logs,
    clearChat,
    chatCustomer,
    chatAppointment,
    triggerUrlRedirection,
    updateIsSubmitted,
    updateContact,
    setPreloadedMessages,
    readOnly,
    setTurnstileToken,
    turnstileToken,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
