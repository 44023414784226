import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  margin: 8px;
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  border-radius: 8px;
  animation: fadeIn 0.3s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ErrorText = styled.span`
  color: #991b1b;
  font-size: 14px;
  font-weight: 500;
`;

const ErrorAlert = ({ message }) => (
  <ErrorContainer>
    <ErrorText>{message}</ErrorText>
  </ErrorContainer>
);

export default ErrorAlert;
