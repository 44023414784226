import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    MOBILE_PHONE: "Mobile Phone",
    CHAT_TEXT_ME: "Text Me",
    WE_GOT_YOUR_MESSAGE: "We got your message",
    TEXT_CHAT_HELP_START_CONVERSATION: "Start your conversation below",
    TEXT_ENTER_YOUR_QUESTION_HERE: "Enter your question here.",
    TEXT_RECEIVED: "Received",
    TEXT_CHAT_HELP_CHOOSE_QUESTION: "Choose from a question below:",
    TERMS: "Terms",
    USE_AS_SUBJECT_TO: "Use as subject to",
    POWERED_BY: "Powered by ",
    TEXT_MESSAGE_FEES_MAY_APPLY: " Text message fees may apply.",
    BY_SUBMITTING_YOU_AGREE_TO_RECEIVE_TEXT_MESSAGES_AT_THE:
      "By submitting you agree to receive text messages at the",
    NUMBER_PROVIDED: "number provided.",
    MESSAGE_DATA_RATES_APPLY: " Message/data rates apply.",
    WHATS_YOUR_NAME: `What’s your name?`,
    THANK_YOU: "Thanks!",
    WILL_CONTACT: "We will contact you soon!",
    BY: "by",
    CLICK_TO_START: "Click to start",
    FIND_YOUR_ANSWER_NOW: "Find your answer now",
    ENGAGE: "Engage",
    SEND_MESSAGE: "Send a message...",
    OPEN_STORE: "Store is currently open",
    OPEN_STORE_SD:
      "Thank you for visiting the Stratégies SD website, how can we assist you?",
    CLOSED_STORE: "Store is currently closed",
    FIND_US: "Find Us",
    REVIEWS: "Reviews",
    NO_REVIEWS_YET: "No Reviews Yet",
    REVIEWS_EMPTY_STATE:
      "It`s a little empty here! No reviews have been submitted yet.",
    SEND_ME_TEXT_MESSAGE: "Send me a text message",
    CTA_THANK_YOU: "We will be texting you very soon!",
    FEE_MAY_APPLY:
      "I authorize the retailer to communicate via sms. Terms and conditions may apply.",
    VISIT: "Visit",
    AIRPORT_MARIN_FOOTER_TEXT:
      "to view a copy of our privacy policy and notice of collection.",
    TEXT_US: "Text Us",
    CALL_US: "Call Us",
    CONSENT_TEXT:
      "I provide consent to be contacted via SMS. Use as subject to",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email",
    PHONE_NUMBER: "Phone number",
    ACCEPT_TERMS_TEXT:
      "To ensure we provide you with the best service, please note that by submitting this form your grant us consent to contact you via phone call and SMS regarding your inquiry.",
    REQUIRED_FIELD: "This field is required!",
    INVALID_PHONE_NUMBER: "Invalid phone number!",
    ENTER_VALID_EMAIL: "Please enter a valid email address",
    FULL_NAME: "Full Name",
    DELIVERED: "Delivered",
    SEND: "Send",
    TO_ANSWER: "For any other questions, please fill out the form below.",
    SHORT_CONSENT: "Consent under",
    TYPE_MESSAGE: "Type your message",
    VIEW_OUR_STORIES: "View our stories",
    CONSENT_TEXT_NEW:
      "I provide consent to be contacted via SMS. I also agree to the",
    PRIVACY_POLICY: "Privacy Policy",
    ACTIVE_CONVERSATIONS: "active conversations on this car",
    GREAT_DEAL: "Great Deal",
    AWARDS: "Awards",
    WELCOME_MESSAGE:
      "Welcome to our shop! I'm here to help with any inquiries you may have.",
    I_AM_INTERESTED: "I'm interested",
    APPOINTMENT_BOOKING: "Appointment Booking",
    DATE: "Date",
    CHOOSE_DATE: "Choose date",
    TIME: "Time",
    CHOOSE_TIME: "Choose time",
    BOOK_APPOINTMENT: "Book an appointment",
    APPOINTMENT_BOOKED: "Appointment booked on {date} at {time}",
    APPOINTMENT_TIMEZONE:
      "Appointment Time Zone: Selected in organization’s time zone",
    CANCEL: "Cancel",
    PRICE_PENDING: "Price Pending",
    CONFIRM_DETAILS: "Confirm Details",
    INITIAL_INTERESTED_MESSAGE: "I'm interested in this vehicle {vin}",
    SCHEDULE_TEST_DRIVE: "I would like to schedule a test drive for {vin}",
    BOOKING_REQUEST: "I would like to book an appointment on {date}, at {time}",
    BOOKING_REQUEST_WITH_NAME:
      "I would like to book an appointment on {date}, at {time}. My name is {name} and my phone number is {phone}",
    BOOKING_REQUEST_SEPARATE_NAMES:
      "I would like to book an appointment on {date}, at {time}. My name is {firstName}, my last name is {lastName} and my phone number is {phone}",
    LEAD_FORM_MESSAGE: "My name is {name} and my phone number is {phone}",
    LEAD_FORM_SEPARATE_NAMES:
      "My name is {firstName}, my last name is {lastName} and my phone number is {phone}",
    READ: "Read",
    SCHEDULE_TEST_DRIVE_LABEL: "Schedule Test Drive",
    FAILED_TO_SEND: "Failed to send",
    RETRY: "Retry",
    NEW_CONSENT_TEXT: "I consent to be contacted. Use as subject to",
    TERMS_AND_CONDITIONS: "Terms and Conditions",
  },
  fr: {
    MOBILE_PHONE: "Téléphone",
    CHAT_TEXT_ME: "Textez-moi",
    WE_GOT_YOUR_MESSAGE: "Nous avons reçu votre message",
    TEXT_CHAT_HELP_START_CONVERSATION: "Commencez la discussion",
    TEXT_ENTER_YOUR_QUESTION_HERE: "Entrez votre question ici.",
    TEXT_RECEIVED: "Reçu",
    TEXT_CHAT_HELP_CHOOSE_QUESTION: "Choisissez un sujet:",
    TERMS: "Termes",
    USE_AS_SUBJECT_TO: "Utiliser selon les ",
    POWERED_BY: "Propulsé par ",
    TEXT_MESSAGE_FEES_MAY_APPLY: ` Des frais de SMS peuvent s'appliquer.`,
    BY_SUBMITTING_YOU_AGREE_TO_RECEIVE_TEXT_MESSAGES_AT_THE:
      "En soumettant, vous acceptez de recevoir des SMS au",
    NUMBER_PROVIDED: "numéro fourni.",
    MESSAGE_DATA_RATES_APPLY: ` Les tarifs de messagerie/données s'appliquent.`,
    WHATS_YOUR_NAME: `Quel est votre nom?`,
    BY: "par",
    CLICK_TO_START: "Cliquez pour commencer",
    FIND_YOUR_ANSWER_NOW: "Trouvez votre réponse maintenant",
    ENGAGE: "Discuter",
    SEND_MESSAGE: "Envoyer un message...",
    OPEN_STORE: "La concession est présentement ouverte",
    OPEN_STORE_SD:
      "Merci de consulter le site de Stratégies SD, comment pouvons-nous vous aider?",
    CLOSED_STORE: "La concession est présentement fermé",
    FIND_US: "Trouvez-nous",
    REVIEWS: "Avis",
    NO_REVIEWS_YET: "Aucun avis pour le moment",
    REVIEWS_EMPTY_STATE:
      "C'est un peu vide ici ! Aucun avis n'a encore été soumis.",
    SEND_ME_TEXT_MESSAGE: "Envoyez-moi un SMS",
    CTA_THANK_YOU: "Nous vous enverrons un SMS très bientôt !",
    FEE_MAY_APPLY:
      "J'autorise le commerçant à communiquer par sms. Des termes et conditions peuvent s'appliquer.",
    THANK_YOU: "Thanks!",
    WILL_CONTACT: "We will contact you soon!",
    VISIT: "Visite",
    AIRPORT_MARIN_FOOTER_TEXT:
      "pour voir une copie de notre politique de confidentialité et avis de collecte.",
    TEXT_US: "SMS",
    CALL_US: "appelez-nous",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom de famille",
    EMAIL: "Courriel",
    PHONE_NUMBER: "Numéro de téléphone",
    ACCEPT_TERMS_TEXT:
      "Pour vous assurer de vous fournir le meilleur service, veuillez noter qu'en soumettant ce formulaire, vous nous accordez votre consentement pour vous contacter par appel téléphonique et SMS concernant votre demande",
    REQUIRED_FIELD: "Ce champ est obligatoire!",
    INVALID_PHONE_NUMBER: "Numéro de téléphone invalide!",
    ENTER_VALID_EMAIL: "veuillez entrer une adresse courriel valide",
    FULL_NAME: "Nom Complet",
    DELIVERED: "Livré",
    SEND: "Envoyer",
    TO_ANSWER:
      "Pour toute autre question, veuillez remplir le formulaire ci-dessous",
    SHORT_CONSENT: "Consentement sous",
    TYPE_MESSAGE: "Tapez votre message",
    VIEW_OUR_STORIES: "Voir nos stories",
    CONSENT_TEXT_NEW: "J'accepte d'être contacté par SMS. J'accepte aussi la",
    PRIVACY_POLICY: "Politique de confidentialité.",
    ACTIVE_CONVERSATIONS: "conversations actives sur cette voiture",
    GREAT_DEAL: "Super affaire",
    AWARDS: "Prix",
    WELCOME_MESSAGE:
      "Bienvenue dans notre boutique! Je suis là pour vous aider avec toutes les questions que vous pourriez avoir.",
    I_AM_INTERESTED: "Je suis intéressé",
    APPOINTMENT_BOOKING: "Prise de rendez-vous",
    DATE: "Date",
    CHOOSE_DATE: "Choisir la date",
    TIME: "Heure",
    CHOOSE_TIME: "Choisir l'heure",
    BOOK_APPOINTMENT: "Prendre rendez-vous",
    APPOINTMENT_BOOKED: "Rendez-vous pris le {date} à {time}",
    APPOINTMENT_TIMEZONE:
      "Heure du rendez-vous : Sélectionnée dans le fuseau horaire de l’organisation",
    CANCEL: "Annuler",
    PRICE_PENDING: "Prix en attente",
    CONFIRM_DETAILS: "Confirmer les détails",
    INITIAL_INTERESTED_MESSAGE: "Je suis intéressé par ce véhicule {vin}",
    SCHEDULE_TEST_DRIVE: "Je voudrais planifier un essai routier pour {vin}",
    BOOKING_REQUEST: "Je voudrais prendre rendez-vous le {date}, à {time}",
    BOOKING_REQUEST_WITH_NAME:
      "Je voudrais prendre rendez-vous le {date}, à {time}. Je m'appelle {name} et mon numéro de téléphone est {phone}",
    BOOKING_REQUEST_SEPARATE_NAMES:
      "Je voudrais prendre rendez-vous le {date}, à {time}. Je m'appelle {firstName}, mon nom de famille est {lastName} et mon numéro de téléphone est {phone}",
    LEAD_FORM_MESSAGE:
      "Je m'appelle {name} et mon numéro de téléphone est {phone}",
    LEAD_FORM_SEPARATE_NAMES:
      "Je m'appelle {firstName}, mon nom de famille est {lastName} et mon numéro de téléphone est {phone}",
    READ: "Lu",
    SCHEDULE_TEST_DRIVE_LABEL: "Planifier un essai routier",
    FAILED_TO_SEND: "Échec de l'envoi",
    RETRY: "Réessayer",
    NEW_CONSENT_TEXT: "J'accepte d'être contacté. Utilisation sous réserve de",
    TERMS_AND_CONDITIONS: "Conditions générales",
  },
  es: {
    MOBILE_PHONE: "Teléfono móvil",
    CHAT_TEXT_ME: "Escríbeme",
    WE_GOT_YOUR_MESSAGE: "Nosotros recibimos tu mensaje",
    TEXT_CHAT_HELP_START_CONVERSATION: "Empiece una conversación",
    TEXT_ENTER_YOUR_QUESTION_HERE: "Escriba su pregunta aquí.",
    TEXT_RECEIVED: "Recibido",
    TEXT_CHAT_HELP_CHOOSE_QUESTION: "Elija una de las siguientes preguntas:",
    TERMS: "Términos",
    USE_AS_SUBJECT_TO: "Uso sujeto a los",
    POWERED_BY: "Desarrollado por ",
    TEXT_MESSAGE_FEES_MAY_APPLY: ` Se pueden aplicar cargos por SMS`,
    BY_SUBMITTING_YOU_AGREE_TO_RECEIVE_TEXT_MESSAGES_AT_THE:
      "Al enviar, acepta recibir mensajes de texto en el",
    NUMBER_PROVIDED: "número proporcionado.",
    MESSAGE_DATA_RATES_APPLY: ` Se aplican tarifas de mensajes/datos.`,
    WHATS_YOUR_NAME: `¿Cuál es tu nombre?`,
    BY: "por",
    CLICK_TO_START: "Haga clic para comenzar",
    FIND_YOUR_ANSWER_NOW: "Encuentra tu respuesta ahora",
    ENGAGE: "Háblanos",
    SEND_MESSAGE: "Enviar un mensaje",
    OPEN_STORE: "Estamos Abiertos",
    OPEN_STORE_SD:
      "Gracias por visitar el sitio web de Stratégies SD, ¿cómo podemos ayudarte?",
    CLOSED_STORE: "Estamos Cerrados",
    FIND_US: "Encuéntranos",
    REVIEWS: "Reseñas",
    NO_REVIEWS_YET: "No hay reseñas",
    REVIEWS_EMPTY_STATE:
      "Está un poco vacío aquí. No han dejado  reseñas   todavía",
    SEND_ME_TEXT_MESSAGE: "Enviame un mensaje",
    CTA_THANK_YOU: "Te enviaremos un mensaje de texto pronto",
    FEE_MAY_APPLY:
      "Autorizo a este vendedor a comunicarse por mensaje de texto. Podrían  aplicar términos y condiciones.",
    THANK_YOU: "Thanks!",
    WILL_CONTACT: "We will contact you soon!",
    VISIT: "Visita",
    AIRPORT_MARIN_FOOTER_TEXT:
      "para ver una copia de nuestra política de privacidad y notificación de recopilación.",
    TEXT_US: "texto",
    CALL_US: "llámanos",
    FIRST_NAME: "Nombre",
    LAST_NAME: "Apellido",
    EMAIL: "Correo electrónico",
    PHONE_NUMBER: "Número de teléfono",
    ACCEPT_TERMS_TEXT:
      "Para asegurarnos de brindarle el mejor servicio, tenga en cuenta que al enviar este formulario nos otorga su consentimiento para contactarlo mediante llamada telefónica y SMS con respecto a su consulta",
    REQUIRED_FIELD: "¡Este campo es obligatorio!",
    INVALID_PHONE_NUMBER: "Número de teléfono no válido",
    ENTER_VALID_EMAIL:
      "Por favor, ingresa una dirección de correo electrónico válida",
    DELIVERED: "Entregado",
    SEND: "Enviar",
    FULL_NAME: "Nombre Completo",
    TO_ANSWER:
      "Para cualquier otra pregunta, complete el formulario a continuación",
    SHORT_CONSENT: "Consentimiento según",
    TYPE_MESSAGE: "Escribe tu mensaje",
    VIEW_OUR_STORIES: "Ver nuestras stories",
    CONSENT_TEXT_NEW: "Acepto ser contactado por SMS. También acepto los",
    PRIVACY_POLICY: "Política de privacidad",
    ACTIVE_CONVERSATIONS: "conversaciones activas en este auto",
    GREAT_DEAL: "Gran oferta",
    AWARDS: "Premios",
    WELCOME_MESSAGE:
      "¡Bienvenido a nuestra tienda! Estoy aquí para ayudarte con cualquier consulta que puedas tener.",
    I_AM_INTERESTED: "Estoy interesado",
    APPOINTMENT_BOOKING: "Reserva de cita",
    DATE: "Fecha",
    CHOOSE_DATE: "Elige fecha",
    TIME: "Hora",
    CHOOSE_TIME: "Elige hora",
    BOOK_APPOINTMENT: "Reservar una cita",
    APPOINTMENT_BOOKED: "Cita reservada el {date} a las {time}",
    APPOINTMENT_TIMEZONE:
      "Zona horaria de la cita: Seleccionada en la zona horaria de la organización",
    CANCEL: "Cancelar",
    PRICE_PENDING: "Precio pendiente",
    CONFIRM_DETAILS: "Confirmar detalles",
    INITIAL_INTERESTED_MESSAGE: "Estoy interesado en este vehículo {vin}",
    SCHEDULE_TEST_DRIVE:
      "Me gustaría programar una prueba de manejo para {vin}",
    BOOKING_REQUEST: "Me gustaría reservar una cita el {date}, a las {time}",
    BOOKING_REQUEST_WITH_NAME:
      "Me gustaría reservar una cita el {date}, a las {time}. Mi nombre es {name} y mi número de teléfono es {phone}",
    BOOKING_REQUEST_SEPARATE_NAMES:
      "Me gustaría reservar una cita el {date}, a las {time}. Mi nombre es {firstName}, mi apellido es {lastName} y mi número de teléfono es {phone}",
    LEAD_FORM_MESSAGE: "Mi nombre es {name} y mi número de teléfono es {phone}",
    LEAD_FORM_SEPARATE_NAMES:
      "Mi nombre es {firstName}, mi apellido es {lastName} y mi número de teléfono es {phone}",
    READ: "Leído",
    SCHEDULE_TEST_DRIVE_LABEL: "Programar una prueba de manejo",
    FAILED_TO_SEND: "Error al enviar",
    RETRY: "Reintentar",
    NEW_CONSENT_TEXT: "Consiento en ser contactado. Uso sujeto a",
    TERMS_AND_CONDITIONS: "Términos y Condiciones",
  },
});

export const getLanguage = (language) => {
  if (language === "es") return "sp";
  return language || "en";
};
