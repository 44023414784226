import { useSelector, useDispatch } from "react-redux";
import {
  setActiveLocation,
  setActiveLocationId,
  setActiveToken,
  setWizData,
} from "../../../../store/reducers/wizModule";
import { useEffect, useState } from "react";

const useWiz = () => {
  const wizData = useSelector((state) => state.wiz.wizData);
  const [finalView, setFinalView] = useState("Default Reply");
  const dispatch = useDispatch();
  const activeLocation = useSelector((state) => state.wiz.activeLocation);
  const activeToken = useSelector((state) => state.wiz.activeToken);
  const activeLocationId = useSelector((state) => state.wiz.activeLocationId);
  const [initialStepType, setInitialStepType] = useState("");

  useEffect(() => {
    if (wizData?.steps?.length > 0) {
      setInitialStepType(wizData.steps[0].type);
    }
  }, [wizData]);

  useEffect(() => {
    findFinalView();
  }, [wizData]);

  const findFinalView = () => {
    if (wizData?.steps?.length > 0) {
      for (const step of wizData.steps) {
        if (step.type === "Final View") {
          setFinalView(step.finalView);
        }
      }
    }
  };

  const setData = (data) => {
    dispatch(setWizData(data));
  };

  const getTriggers = () => {
    return activeLocation?.triggers;
  };

  const getQuestions = () => {
    if (wizData?.steps?.length > 0) {
      for (const step of wizData.steps) {
        if (step.customQuestions && Array.isArray(step.customQuestions)) {
          return step.customQuestions;
        } else if (
          step.questionsAndLocations &&
          Array.isArray(step.questionsAndLocations)
        ) {
          return step.questionsAndLocations;
        }
      }
    }

    return [];
  };

  const getContentType = () => {
    return wizData?.steps[0]?.type;
  };

  const extractUniqueLocations = (data) => {
    const uniqueLocations = new Map();

    data.forEach((item) => {
      item.locations.forEach((locationItem) => {
        const locationId = locationItem.locationId;
        if (!uniqueLocations.has(locationId)) {
          uniqueLocations.set(locationId, locationItem);
        }
      });
    });

    return Array.from(uniqueLocations.values());
  };

  const getLocationsByQuestionId = (questionId) => {
    if (questionId === "CUSTOM_QUESTION") {
      const data = extractUniqueLocations(
        wizData?.steps[0]?.questionsAndLocations
      );
      return data;
    }
    if (wizData?.steps[0]?.type === "Questions -> Locations") {
      const questionIndex = wizData?.steps[0]?.questionsAndLocations?.findIndex(
        (item) => item.questionId === questionId
      );
      return wizData?.steps[0]?.questionsAndLocations[questionIndex].locations;
    }
  };

  const getLocations = () => {
    if (wizData?.steps[0]?.type === "Locations") {
      return wizData?.steps[0]?.locations;
    }
  };

  const updateActiveLocation = (location) => {
    dispatch(setActiveLocation(location));
  };

  const updateActiveToken = (token) => {
    dispatch(setActiveToken(token));
  };

  const updateActiveLocationId = (locationId) => {
    dispatch(setActiveLocationId(locationId));
  };

  return {
    wizData,
    wizSteps: wizData?.steps || [],
    setWizData: setData,
    getWizQuestions: getQuestions,
    finalView,
    getContentType,
    getLocations,
    activeLocation,
    getTriggers,
    setActiveLocation: updateActiveLocation,
    getLocationsByQuestionId,
    updateActiveToken,
    activeToken,
    initialStepType,
    activeLocationId,
    updateActiveLocationId,
  };
};

export default useWiz;
