import { createSlice, createSelector } from "@reduxjs/toolkit";

const wizSlice = createSlice({
  name: "wiz",
  initialState: {
    wizData: null,
    currentStep: 0,
    activeLocation: null,
    activeToken: "",
    activeLocationId: "",
  },
  reducers: {
    setWizData: (state, action) => {
      state.wizData = action.payload;
    },
    nextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    previousStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    changeStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    setActiveToken: (state, action) => {
      state.activeToken = action.payload;
    },
    setActiveLocationId: (state, action) => {
      state.activeLocationId = action.payload;
    },
  },
});

export const {
  setWizData,
  nextStep,
  previousStep,
  changeStep,
  setActiveLocation,
  setActiveToken,
  setActiveLocationId,
} = wizSlice.actions;
export default wizSlice.reducer;

export const selectWiz = (state) => state.wiz;

export const wizActiveStep = createSelector([selectWiz], (state) => {
  if (state?.wizData?.steps?.length) {
    return state?.wizData.steps[state.currentStep];
  }
});
