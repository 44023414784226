import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Linkify from "linkify-react";
import Lottie from "react-lottie";

import { Iconset } from "../../../components/Icons/Icons";
import { strings } from "../../../utils/localization";
import checkmarkAnimation from "./assets/animations/checkmark.json";
import deliveredAnimation from "./assets/animations/delivered.json";

const checkmarkOptions = {
  loop: false,
  autoplay: true,
  animationData: checkmarkAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const deliveredOptions = {
  loop: false,
  autoplay: true,
  animationData: deliveredAnimation,
  initialSegment: [25, 90],
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${({ isSent }) => (isSent ? "flex-end" : "flex-start")};
  align-items: flex-end;
  margin-bottom: 10px;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  z-index: 2;
  object-fit: cover;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultAvatar = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyAvatar = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-right: 10px;
`;

const AvatarContainer = styled.div`
  margin-right: 10px;
`;

const RightMessageContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px 12px 0px 12px;
  width: fit-content;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-left: 40px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-right: 15px;
  position: relative;
  width: 100%;

  animation: slideMatadorAnimation 0.5s forwards;

  @keyframes slideMatadorAnimation {
    0% {
      left: -50px;
    }
    100% {
      left: 0;
    }
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ErrorText = styled.span`
  color: #ef4444;
  font-size: 12px;
`;

const RetryButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.color || "#4B5563"};
  font-size: 12px;
  cursor: pointer;
  padding: 2px 8px;
  border-radius: 4px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const LeftMessageContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #cdd9ea;
  max-width: 80%;
  max-width: 80%;
`;

const MessageText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  word-break: break-word;
  color: ${(props) => props.color};
`;

const Status = styled.div`
  color: #7e7e7e;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;

const Text = styled.span`
  margin-left: 2px;
`;

const Icon = styled.div`
  padding-top: 2px;
`;

const FormWrapper = styled.div`
  margin-top: 10px;
`;

const TimeStamp = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => props.color};
  opacity: 0.8;
  margin-top: 4px;
`;

const ToggleButton = styled.div`
  margin-top: 10px;
  background-color: #332c6e;
  color: #fff;
  padding: 10px 12px;
  border-radius: 8px;
  font-size: 14px;
  width: 75%;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
`;

const Message = ({
  message,
  avatarImg,
  secondaryColor,
  color,
  timestamp,
  SubmitForm,
  nextIsSent,
  scrollToBottom,
  UserLeadForm,
  isLastMessage,
  onRetry,
  readOnly,
}) => {
  const [userFormVisible, setUserFormVisible] = useState();
  const [userAppointmentBookingVisible, setUserAppointmentBookingVisible] =
    useState();
  const { text, role, type } = message;
  const isSent = role === "user";
  const [currentAnimation, setCurrentAnimation] = useState(
    isLastMessage ? "checkmark" : "delivered"
  );

  useEffect(() => {
    setTimeout(() => {
      setCurrentAnimation("delivered");
    }, 2500);
  }, []);

  const renderAvatar = () => {
    if (isSent) return null;

    if (!isSent && nextIsSent === false) return <EmptyAvatar />;

    let content = null;

    if (avatarImg) {
      content = <Avatar src={avatarImg} alt="avatar" />;
    } else {
      content = <DefaultAvatar>{Iconset.noImageDefault}</DefaultAvatar>;
    }

    return <AvatarContainer>{content}</AvatarContainer>;
  };

  const handleUserFormClick = () => {
    if (readOnly) return;
    setUserFormVisible(true);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  const handleUserAppointmentBookingClick = () => {
    if (readOnly) return;
    setUserAppointmentBookingVisible(true);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  const renderUserForm = () => {
    if (userFormVisible) {
      return (
        <FormWrapper>
          {SubmitForm({
            message,
          })}
        </FormWrapper>
      );
    }

    return (
      <ToggleButton
        color={color}
        secondaryColor={secondaryColor}
        onClick={handleUserFormClick}
        readOnly={readOnly}
      >
        {strings.CONFIRM_DETAILS}
      </ToggleButton>
    );
  };

  const renderUserAppointmentBooking = () => {
    if (userAppointmentBookingVisible) {
      return (
        <FormWrapper>
          {UserLeadForm({
            message,
          })}
        </FormWrapper>
      );
    }

    return (
      <ToggleButton
        color={color}
        secondaryColor={secondaryColor}
        onClick={handleUserAppointmentBookingClick}
        readOnly={readOnly}
      >
        {strings.CONFIRM_DETAILS}
      </ToggleButton>
    );
  };

  const renderStatus = () => {
    if (message.status === "failed") {
      return (
        <Status>
          <ErrorContainer>
            <ErrorText>{strings.FAILED_TO_SEND}</ErrorText>
            <RetryButton onClick={() => onRetry(message)}>
              {strings.RETRY}
            </RetryButton>
          </ErrorContainer>
        </Status>
      );
    }

    const widthHeight = currentAnimation === "checkmark" ? 15 : 20;

    return (
      <Status>
        <Icon>
          <Lottie
            options={
              currentAnimation === "checkmark"
                ? checkmarkOptions
                : deliveredOptions
            }
            height={widthHeight}
            width={widthHeight}
          />
        </Icon>
        <Text>
          {currentAnimation === "checkmark" ? strings.DELIVERED : strings.READ}
        </Text>
      </Status>
    );
  };

  const renderText = () => {
    if (type === "user_form_booking") {
      return (
        <div>
          <LeftContainer>
            <LeftMessageContainer>
              <MessageText color="#222">{text}</MessageText>
            </LeftMessageContainer>
          </LeftContainer>
          {renderUserAppointmentBooking()}
        </div>
      );
    }
    if (type === "user_form") {
      return (
        <div>
          <LeftContainer>
            <LeftMessageContainer>
              <MessageText color="#222">{text}</MessageText>
            </LeftMessageContainer>
          </LeftContainer>
          {renderUserForm()}
        </div>
      );
    }

    if (isSent) {
      return (
        <RightContainer>
          <RightMessageContainer style={{ backgroundColor: color }}>
            <MessageText color={secondaryColor}>{text}</MessageText>
            <TimeStamp color={secondaryColor}>{timestamp}</TimeStamp>
          </RightMessageContainer>
          {renderStatus()}
        </RightContainer>
      );
    } else {
      return (
        <LeftContainer>
          <LeftMessageContainer>
            <MessageText color="#222">
              <Linkify options={{ target: "_blank" }}>{text}</Linkify>
            </MessageText>
          </LeftMessageContainer>
        </LeftContainer>
      );
    }
  };

  return (
    <MessageContainer isSent={isSent}>
      {renderAvatar()}
      {renderText()}
    </MessageContainer>
  );
};

export default Message;
