import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { useChatContext } from "../../contexts/ChatContext";

const TurnstileWidget = forwardRef((props, ref) => {
  const { setTurnstileStatus, handleError } = props;
  const [retryCount, setRetryCount] = useState(0);

  const localRef = useRef(null);

  const { setTurnstileToken } = useChatContext();

  useImperativeHandle(ref, () => ({
    reset: () => {
      localRef.current?.reset();
    },
  }));

  const onError = () => {
    if (retryCount < 3) {
      setRetryCount(retryCount + 1);
      localRef.current?.reset();
    } else {
      handleError?.("Verification error");
    }
  };

  return (
    <Turnstile
      ref={localRef}
      siteKey="0x4AAAAAAA5YSuWDpwpgy3tz"
      onSuccess={(token) => {
        setTurnstileStatus("solved");
        setTurnstileToken(token);
        setRetryCount(0);
      }}
      onError={onError}
      onExpire={() => {
        localRef.current?.reset();
      }}
      options={
        {
          //theme: "light",
          //size: "normal",
        }
      }
    />
  );
});

export default TurnstileWidget;
