import React from "react";
import styled from "styled-components";

const Input = styled.input`
  margin-top: 5px;
  background: #fff;
  box-sizing: border-box;
  padding: 13px;
  width: 100%;
  height: 52px;
  resize: none;
  outline: none;
  position: relative;
  border-color: red;
  border: 1px solid #e5e9f2;
  border-radius: 100px;
  padding-right: 55px;
  touch-action: manipulation;
  font-size: 16px;

  &::placeholder {
    color: #77808d;
  }
`;

const SubmitButton = styled.div`
  width: 40px;
  height: 40px;
  background: ${(props) => props.color};
  border-radius: 40px;
  position: absolute;
  top: 11px;
  right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Container = styled.div`
  border-radius: 12px;
  position: relative;
  padding-right: 16px;
  padding-left: 10px;
  margin-bottom: 5px;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  margin-top: 5px;
`;

const SendIcon = ({ color = "#fff" }) => (
  <svg
    alt="Send"
    tabIndex={10}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1547 16.1304L7.16271 11.3416C7.03262 11.1406 7.0444 10.8687 7.19806 10.6677L11.7015 5.12365C11.8316 4.96997 11.6187 4.75713 11.4532 4.87532L5.94462 9.41427C5.75549 9.57976 5.48356 9.59153 5.28255 9.46144L0.505623 6.48125C0.0917823 6.2329 0.174429 5.60631 0.635489 5.45269L15.3776 0.548902C15.8268 0.40711 16.2525 0.832806 16.1107 1.28204L11.1833 16.0005C11.0297 16.4615 10.4031 16.5442 10.1547 16.1304Z"
      fill={color}
    />
  </svg>
);

const MessagingInput = ({
  color,
  arrowColor = "#fff",
  disabled,
  submit,
  handleChange,
  value,
  maxLength = 300,
  inputPlaceholder,
}) => {
  const onSubmit = () => {
    if (!disabled) {
      submit();
    }
  };

  return (
    <Container>
      <Content>
        <Input
          placeholder={inputPlaceholder}
          value={value}
          onChange={(e) => {
            if (e.target.value.length <= maxLength) {
              handleChange(e);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          maxLength={maxLength}
        />
        <SubmitButton color={color} onClick={onSubmit} disabled={disabled}>
          {SendIcon({ color: arrowColor })}
        </SubmitButton>
      </Content>
    </Container>
  );
};

export default MessagingInput;
